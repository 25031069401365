/* CUSTOM STYLES with some Bootstrap 3 overrides
    Extra small devices (phones, up to 480px): No media query since this is the default in Bootstrap
    Small devices (tablets, 768px and up): @media (min-width: @screen-sm) { ... }
    Medium devices (desktops, 992px and up): @media (min-width: @screen-md) { ... }
    Large devices (large desktops, 1200px and up): @media (min-width: @screen-lg) { ... }
*/

/* basic layout: stretch main content so that full vertical height is always used */

html, body, #root {
    width: 100%;
    height: 100%;
}

#root > div {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

#loader {
    background: white;
}

#loader > div {
    margin: auto;
}

header > div, main, footer {
    flex-shrink: 0;
    background-color: #ffffff;
    margin: 0 10px 10px;
}

h1, h2, h3, p {
    margin: 0 0 16px;
}

h1 {
    font-size: 28px;
    line-height: 32px;
}

h2, h3 {
    font-size: 26px;
    font-weight: bold;
}

#thank-you h2 {
    font-weight: normal;
}

p {
    font-size: 16px;
}


/* text size overrides */

.tx-xs p {
    font-size: 12px;
}
.tx-sm p {
    font-size: 14px;
}
.tx-md p {
    font-size: 16px;
}
.tx-lg p {
    font-size: 18px;
}
.tx-xl p {
    font-size: 20px;
}
.tx-xs p {
    font-size: 12px;
}

.tx-xs h2, .tx-xs h3 {
    font-size: 22px;
}
.tx-sm h2, .tx-sm h3 {
    font-size: 24px;
}
.tx-md h2, .tx-md h3 {
    font-size: 26px;
}
.tx-lg h2, .tx-lg h3 {
    font-size: 28px;
}
.tx-xl h2, .tx-xl h3 {
    font-size: 30px;
}


/* general fonts and colors */

body {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400;
    color: #000000;
    background-color: rgba(6, 54, 120, 0.22); /* #063678 @ 0.22 */
    max-width: 1016px;
    margin: 0 auto;
}

a, header {
    color: #00377b; /* dark blue */
}


/* test environment stuff */

#test-indicator, #test-site-info-container {
    display: none; /* hide by default (in production) */
}

body.test {
    /* modify background in test mode */
    background-color: rgba(216, 89, 24, 0.22); /* #d85918 @ 0.22  */
}

body.test #test-indicator {
    display: inline;
    color: rgb(216, 89, 24);
}
/* text diagonally over main content background
body.test main, body.test .modal {
    background-position: center center;
    background-repeat: repeat-y;
    background-size: contain;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4NTAiIGhlaWdodD0iNDUwIj48Zm9yZWlnbk9iamVjdCBmaWxsPSIjZWNhYmFiIiBzdHJva2U9IiMyMjIyMjIiIHN0eWxlPSJjb2xvcjogcmdiYSgyMTYsIDg5LCAyNCwgMC4yMik7IHRleHQtYWxpZ246IGNlbnRlcjsiIGZvbnQtc2l6ZT0iMTAwIiBmb250LWZhbWlseT0iQXJpYWwsIEhlbHZldGljYSwgc2Fucy1zZXJpZiIgeD0iLTEwMCIgeT0iMTAwIiB3aWR0aD0iMTAwMCIgaGVpZ2h0PSIxMDAiIGZvbnQtd2VpZ2h0PSJib2xkIiB0cmFuc2Zvcm09InJvdGF0ZSgxNSwgMzgwLCAyNTApIj5UIMKgwqAgRcKgIMKgIFMgwqDCoCBUIMKgwqAgSTwvZm9yZWlnbk9iamVjdD48L3N2Zz4=);
}*/


/* header section (logo/text and settings) */

header {
    /* enable re-ordering of children */
    display: flex;
    flex-direction: column;
}

#heading {
    padding-bottom: 32px;
}

#heading .row {
    /* center the logo and texts */
    display: flex;
    align-items: center;
}

#poliisi-logo {
    max-height: 80px;
}

@media (min-width: 768px) {
    /* sm and up: adjust the logo position upwards for better alignment */
    #poliisi-logo {
        margin-top: -16px;
        margin-left: 16px;
    }
}

header h1 {
    font-weight: bold;
    margin: 0;
}

header h2 {
    font-weight: normal;
}

@media (max-width: 450px) {
    /* smaller than xs only: adjust header to fit better */
    header h1, h2 {
        font-size: 20px;
        line-height: 22px;
    }
}

#settings {
    font-size: 13px;
    margin: 10px 10px 0;
    padding: 16px 16px 0;
}

#language-flags button {
    margin-right: 8px;
    padding: 0;
    background-color: transparent;
    border: none;
}

#language-flags button:not(:first-child)::before {
    /* separator between language names */
    content: '|';
    margin-right: 12px;
    padding: 0;
}

#text-sizes button {
    background-color: #00377B;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    min-width: 25px;
    margin-left: 4px;
    padding: 3.5px;
}

@media (max-width: 767px) {
    /* xs / smaller than sm only: */
    header > :nth-child(1) {
        /* #settings buttons last on mobile */
        order: 2;
    }
    header > :nth-child(2) {
        /* heading logo/texts first on mobile */
        order: 1;
    }
    /* fix section margins when header is re-ordered */
    #settings {
        margin-top: 0;
    }

    #heading {
        margin-top: 10px;
        padding: 16px;
    }
    /* hide text size selectors on mobile */
    #text-sizes {
        display: none;
    }
}


/* main content section (page view) */

main {
    flex-grow: 1;
    padding: 60px 0;
}
@media (max-width: 767px) {
    /* xs / smaller than sm only: */
    main {
        padding: 16px;
    }
}

main h3 {
    margin-top: 60px;
}

.subject-text {
    background-color: rgba(182, 182, 182, 0.3); /* #b6b6b6 @ 0.30 */
    font-size: 16px;
    font-weight: bold;
    margin: 32px 0;
    padding: 24px;
}

.extra-info-field {
    border: 1px solid #707070;
    border-radius: 6px;
    padding: 5px;
    width: 100%;
}

.extra-info-field {
    max-width: 100%;
}

.extra-info-field:focus {
    border-color: #00BAF2;
    box-shadow: 0 0 2px 1px #00BAF2;
    outline: none;
}

.extra-info-field:invalid {
    /*border-color: red;
    box-shadow: 0 0 2px 1px red;*/
    outline: none;
}


/* footer section (links) */

footer {
    padding: 40px 16px 24px;
}

.footer-text {
    font-size: 13px;
    line-height: 16px;
}

#isfp-logo {
    width: 250px;
}

@media (max-width: 991px) {
    /* xs-sm: margin between text & image */
    .footer-text {
        margin-bottom: 16px;
    }
}
@media (max-width: 450px) {
    /* smaller than xs only: adjust footer even more to fit better */
    footer {
        padding: 32px 16px 16px;
    }
    .footer-text {
        width: 100%;
    }
}


/* buttons and icons */

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.upload-btn-wrapper input[type=file]:not(:disabled), .upload-btn-wrapper input[type=file]:not(:disabled)::-webkit-file-upload-button {
    cursor: pointer;
}

.upload-btn button {
    margin-right: 8px;
}

.rounded-button {
    color: white;
    border: none;
    border-radius: 50%;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    line-height: 1;
}

.submit-btn {
    background-color: rgba(0, 55, 123, 0.85); /* #00377b; */
    border-radius: 20px;
    font-size: 18px;
    height: 40px;
    min-width: 180px;
    margin-top: 32px;
}

.upload-select {
    background-color: rgba(0, 55, 123, 0.85); /* #00377b; */
    width: 32px;
    height: 32px;
    font-size: 32px;
}

.upload-btn-wrapper:hover .upload-select:not(:disabled), .submit-btn:not(:disabled):hover, .submit-btn:not(:disabled):active {
    background-color: #00BAF2;
}

.upload-btn-wrapper:hover label:not(.disabled) {
    color: #00BAF2;
}

.upload-success, .upload-failure, .upload-remove, .upload-close {
    width: 25px;
    height: 25px;
    font-size: 15px;
    padding-top: 5px;
}

table .upload-success, table .upload-failure, table .upload-remove {
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -5px;
}

.upload-success {
    background-color: rgba(82, 159, 24, 1); /* #529f18; */
}

.upload-failure {
    background-color: red;
}

.upload-remove, .upload-close {
    background-color: #fff;
    color: #000;
}

.upload-remove {
    padding-top: 2px;
}

.upload-close:hover, .upload-remove:hover, .upload-remove:focus {
    background-color: rgba(150, 0, 0, 0.1); /* darkish red */
    color: red;
}

button:disabled {
    background-color: #B6B6B6;
}

label.disabled {
    color: #B6B6B6;
}


/* tables */

th, td {
    border-top: none;
    border-bottom: 1px solid #707070 !important;
}

td:first-child, th:first-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

td:last-child, th:last-child {
    padding-right: 0 !important;
}

.dataTables_info {
    display: none;
}

#uploads_wrapper {
    word-break: break-word;
}

#uploads_wrapper .upload-description {
    width: 133%;
}


/* progress bar and description */

progress {
    display: block; /* default: inline-block */
    border: 0 none;
    background: #e8e8e8;
    border-radius: 5px;
}

progress::-moz-progress-bar {
    border-radius: 5px;
    background: #7db155;
}

progress::-webkit-progress-bar {
    background: transparent;
}

progress::-webkit-progress-value {
    border-radius: 5px;
    background: #7db155;
}

.upload-progress {
    margin-top: 16px;
    height: 14px;
    width: 133%;
}

.upload-description {
    clear: both;
    font-style: italic;
}


/* modal file upload dialog */

.popup-overlay .modal {
    display: block;
    position: static;
}

.modal .file-description input {
    margin-left: 1px;
    margin-top: 8px;
    max-width: unset;
    width: 133%; /* sm only */
}

@media (max-width: 767px) {
    /* xs / smaller than sm only: full screen modal */
    .popup-overlay {
        background: #fff !important;
    }
    .popup-content {
        border: none !important;
        margin: 0 !important;
        width: 100% !important;
    }

    .modal .file-description input {
        width: 145%;
    }
}
@media (min-width: 992px) {
    /* larger than sm only */
    .modal .file-description input {
        width: 119%;
    }
}

.modal .queue-file-select {
    margin-bottom: 0;
}

.modal .upload-note, .modal .upload-warning {
    font-size: 13px;
}

.modal .upload-warning .glyphicon {
    color: red;
}

.modal .upload-warning .glyphicon {
    font-size: 16px;
    margin-right: 8px;
}

@media (max-width: 450px) {
    .modal .upload-warning {
        margin-top: 8px;
    }
}

.modal .submit-btn {
    margin-top: 12px;
}

.modal .upload-cancel button {
    background: transparent;
    border: none;
    outline: none;
    color: #00377b;
    cursor: pointer;
    font-weight: bold;
    margin-top: 30px;
}

.modal .upload-cancel button:hover, .modal .upload-cancel button:active {
    color: #00BAF2;
}


/* reCaptcha */

.no-scroll-body {
    height: 100%;
    overflow: hidden
}

#upload_disabler {
    background-color: rgba(128, 128, 128, 0.7);
    margin-top: -60px;
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
}

#upload_disabler_inner {
    height: 30%;
    margin: auto 30px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    background-color: white;
    padding: 1.5em;
}

@media (max-width: 450px) {
    /* smaller than xs only: */
    #upload_disabler_inner {
        padding: 1.5em 0;
        margin-left: 0;
        margin-right: 0;
    }
}

#upload_disabler_inner2 {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50%;
}

.g-recaptcha {
    display: inline-block;
}
